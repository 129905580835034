export const SET_SOCKET = 'SET_SOCKET';
export const NEW_ROLL = 'NEW_ROLL';
export const FULL_ROLL = 'FULL_ROLL';
export const ROLL_UID = 'ROLL_UID';
export const CLEAR_LAST_ROLL = 'CLEAR_LAST_ROLL';
export const MOVE_TO_ROOM = 'MOVE_TO_ROOM';
export const ROLLS_HISTORY = 'ROLLS_HISTORY';
export const ROOM_STATUS = 'ROOM_STATUS';
export const LOGIN_VERIFIED = 'LOGIN_VERIFIED';
export const USERNAME = 'USERNAME';
export const USER_PARAMS = 'USER_PARAMS';
export const DICE_SET = 'DICE_SET';
export const CLEAR_HISTORY = 'CLEAR_HISTORY';
